
.content-section-container {
  margin-bottom: -10px;

  .overlay {
    position: absolute; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    //height: 100vh; /* Full height (cover the whole page) */
    //max-height: 100%;
    background-color: black;
    opacity: 0.15;
    z-index: 1;
  }
  .content {
    display: none;
    &.visible {
      display: block;
    }
    width: 100%;
    position: sticky;
    top: 0;
    padding-top: 1px; // safari border fix

    canvas {
      position: sticky;
    }

  }
  
}
