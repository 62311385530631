@import "variables";

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background: black;
  overflow-x: hidden;
  overflow-y: hidden;

  &.loaded {
    overflow-y: unset;
  }
}

.wrapper {
  padding: 0 20px;
}
