@import "variables";

.landing-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  margin-bottom: -5px;
}

.landing-text-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  position: absolute;
  width: 100%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.landing-title {
  width: 100%;
}

// https://codepen.io/vickymos/pen/LYPzZNR
$line-height: 50px;
$line-height-abs: -50px;

.c-scrolldown {
  width: 2px;
  height: $line-height;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 12vh;
  overflow: hidden;

  .c-line {
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 50%);
    background-position: 0 $line-height-abs;
    background-size: 100% 200%;
    animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
  }
}

@keyframes scrolldown {
  0% {
    background-position: 0 $line-height-abs;
  }
  75% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 $line-height;
  }
}

.scroll-text {
  font-size: $font-size-small;
  color: white;
  position: absolute;
  text-align: center;
  top: 88%;
  width: 100%;
}
