

$PT-Mono: "PT Mono", monospace;

$font-size-xx-small: 12px;
$font-size-x-small: 16px;
$font-size-small: 18px;
$font-size-medium: 22px;
$font-size-large: 26px;
$font-size-x-large: 40px;

$font-line-height-xx-small: 14px;
$font-line-height-x-small: 18px;
$font-line-height-small: 24px;
$font-line-height-medium: 28px;
$font-line-height-large: 32px;
$font-line-height-x-large: 48px;

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

$mobile-medium: 375px;
$mobile-large: 425px;
$desktop: 600px;