@import "variables";

* {
  font-family: $PT-Mono;
}

h1 {
  margin: 0;
  font-size: $font-size-x-large;
  font-style: normal;
  font-weight: $bold;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
}

h2 {
  margin: 0;
  font-size: $font-size-large;
  line-height: $font-line-height-large;
  font-weight: $normal;
  @media (min-width: $desktop) and (min-height: $desktop) {
    font-size: $font-size-x-large;
    line-height: $font-line-height-x-large;
  }
}

h3 {
  margin: 0;
  font-size: $font-size-medium;
  line-height: $font-line-height-medium;
  font-style: normal;
  font-weight: $bold;

  @media (min-width: $desktop) and (min-height: $desktop)  {
    font-size: $font-size-x-large;
    line-height: $font-line-height-x-large;
  }
}

h4 {
  margin: 0;
  font-size: $font-size-medium;
  line-height: $font-line-height-medium;
  font-weight: $normal;
  @media (min-width: $desktop) and (min-height: $desktop) {
    font-size: $font-size-large;
    line-height: $font-line-height-large;
  }
}

h5 {
  margin: 0;
  font-size: $font-size-x-small;
  line-height: $font-line-height-x-small;
  font-weight: $normal;

  @media (min-width: $desktop) and (min-height: $desktop) {
    font-size: $font-size-small;
    line-height: $font-line-height-small;
  }
}

p {
  margin: 0;
  font-size: $font-size-small;
  line-height: $font-line-height-small;
  font-weight: $normal;
  white-space: pre-line;

  @media (min-width: $desktop) and (min-height: $desktop) {
    font-size: $font-size-large;
    line-height: $font-line-height-large;
    &.intro-text {
      font-size: $font-size-small;
      line-height: $font-line-height-small;
    }
  }
}
