@import "variables";

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15vh 0;
  margin-bottom: -20px;
  min-height: 50vh;

  .text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 30px;
    max-width: 600px;

    .intro-title {
      margin-bottom: 32px;
    }

    .intro-text {
      margin-bottom: 32px;
    }
  }

  img.intro-image {
    width: 100%;
  }
}
