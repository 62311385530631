@import "variables";

.text-section {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  .text-container {
    text-align: left;
    padding: 0 30px;
    margin-top: -25%;
    max-width: 600px;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1.5s, visibility 0s ease-in;

    @media (min-width: $desktop) {
      text-align: left;
      margin-top: -10%;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .title {
      
      margin-bottom: 20px;
      
    }

    .subtitle {
      margin-bottom: 20px;
    }

    .description {
      margin-bottom: 20px;
    }

    .interview-title {
      margin-bottom: 10px;
      font-size: $font-size-small;
      line-height: $font-line-height-small;
      font-weight: $normal;
      white-space: pre-line;
      text-align: center;

      @media (min-width: $mobile-medium) {
        font-size: $font-size-medium;
        line-height: $font-line-height-medium;
      }

      @media (min-width: $desktop) {
        font-size: $font-size-large;
        line-height: $font-line-height-large;
      }
    }

    .interview-description {
      font-size: $font-size-xx-small;
      line-height: $font-line-height-xx-small;
      font-weight: $normal;
      white-space: pre-line;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: $mobile-medium) {
        font-size: $font-size-x-small;
        line-height: $font-line-height-x-small;
      }

      @media (min-width: $desktop) {
        font-size: $font-size-small;
        line-height: $font-line-height-small;
      }
    }

    .video {
      height: 30vh;
      width: 100%;
      margin-bottom: 10px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .links {
      .link {
        margin-right: 20px;
        text-decoration: underline;
        transition: opacity 0.15s ease-in;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.slidecontainer {
  position: absolute;
  top: 70%;
  padding: 0 30px;
  max-width: 600px;
  left: 0;
  right: 0;
  margin: 20px auto 0 auto;
  z-index: 2;

  @media (min-width: $desktop) and (min-height: $desktop) {
    margin: 60px auto 0 auto;

  }

  .slider-labels {
    display: flex;
    margin-top: 9px;
    white-space: pre-line;

    .slider-label {
      width: 50%;

      &.start {
        text-align: left;
      }

      &.end {
        text-align: right;
      }
    }
  }

 
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
}
